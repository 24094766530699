<template>

	<Teleport to="body">
		<div class="modal-bg">
			<div class='general-modal-container'  ref="modalWindow">
				<div class='general-modal-header-container'>
					<div>网页对话</div>
					<img class="general-modal-header-close-btn" src="../images/close_white.svg" alt="close"
					     @click="this.onClose">
				</div>
				<div class="topic-detail-container">
					<div class='topic-title'>指纹：{{ this.chatItem?.fp }}</div>


					<div class='topic-conversation'>对话：<br></div>
					<template v-for="(item) in this.chatList" :key="item.recordId">
						<div class="cs-chat-content-wrapper">
							<div
								:class="[item.type === 'customer' ? 'cs-topic-detail-client-chat' : 'cs-topic-detail-cs-chat']"
								style="width: 100%;text-align: start;">
								<div class="chat-box-header">
									<div class="chat-box-talker-container">
										{{ (item.type === 'customer' ? '用户：\n' : '我：\n') }}
									</div>
									<!--									<img src="../images/delete_chat.svg" alt="close topic" v-if="item.role !== 'client'"-->
									<!--									     style="width: 1.2em;cursor: pointer;">-->
								</div>
								<div
									:class="[item.type === 'customer' ? 'chat-box-client-msg-container' : 'chat-box-my-msg-container']"
									v-if="item.msg">
									{{ item.msg }}
								</div>
								<img :src="item.image"
								     :class="[item.type === 'customer' ? 'chat-box-client-img-container' : 'chat-box-my-img-container']"
								     v-if="item.image"/>


								<div style="width: 100%;text-align: right;">{{ item.createTimeString }}</div>
							</div>
						</div>
					</template>
				</div>

				<div class="editor-container">
					<textarea class='cs-input' type="text" placeholder='请在此输入信息' rows="3"
					          v-model="this.toSendMsg"/>
					<div class="editor-btn-container">
						<a-button type="primary" size="middle" @click="sendMsg"
						          style="color: #191123FF;background-color: #FF8900;margin-left: 0.2em;margin-bottom: 0.2em;">
							发送
						</a-button>
						<!--					<a-button type="primary" size="middle" @click="sendMsgReply"-->
						<!--					          style="color: #191123FF;background-color: #FF8900;margin-left: 0.2em;">-->
						<!--						<img src="../images/attachment.svg" alt="attachment">-->
						<!--					</a-button>-->
					</div>

				</div>
			</div>
		</div>
	</Teleport>

</template>
<script>

import {hideLoading, postRequestAsync, showLoading, getFingerprint} from "@/utils/methods";

export default {
	name: "WebsiteChatDetailModal",
	data() {
		return {
			chatList: [],
			toSendMsg: ''
		}
	},
	props: {
		chatItem: {
			type: Object,
			required: true,
			isTouched: false
		}
	},
	inject: ['isMobile'],
	async mounted() {
		await this.getWebsiteChatList()
		if (this.isMobile) {
			this.setHeight()
			setTimeout(()=>{
				this.setHeight()
			}, 1000)
			setTimeout(()=>{
				this.setHeight()
			}, 2000)
		}
	},
	methods: {
		onClose() {
			this.$emit('close')
		},
		async sendMsg() {
			if (!this.toSendMsg) {
				return
			}
			showLoading()
			const fp = this.chatItem?.fp
			const result = await postRequestAsync('admin/cs/replyWebsiteChat', {fp: fp, msg: this.toSendMsg})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.chatList = result?.msg?.list
				this.toSendMsg = ''

			}
			this.$emit('msgSent')
		},
		async getWebsiteChatList() {
			showLoading()
			const fp = this.chatItem?.fp
			const result = await postRequestAsync('admin/cs/getWebsiteChatByFp', {fp: fp})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.chatList = result?.msg?.list

			}
		},
		onTouchStart(e) {
			e.preventDefault();
			this.isTouched = true;
			// 处理逻辑
			setTimeout(() => {
				this.isTouched = false;
			}, 300); // 300ms后重置
		},
		onClick(e) {
			// 如果是触摸触发的，则不再执行click事件
			if (this.isTouched) return;
			// 处理点击逻辑
		},
		setHeight() {
			this.windowHeight = window.innerHeight
			if (this.$refs.modalWindow) {
				this.$refs.modalWindow.style.height = `${this.windowHeight}px`
			}
		}
	}
}
</script>

<style scoped>
.modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*backdrop-filter: blur(8px) opacity(100%) brightness(90%);*/
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	backdrop-filter: blur(4px) opacity(100%) brightness(90%);
	pointer-events: auto;
	touch-action: none;
}

.general-modal-container {
	position: relative;
	background-color: #1f1f35;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	padding: 0.4em;
}

.topic-title {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	word-wrap: break-word;
}

.topic-type {
	width: fit-content;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	word-wrap: break-word;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.topic-email {
	width: 100%;
	font-size: 1.2em;
	color: rgba(230, 213, 184, 0.8);
	text-align: start;
}

.user-detail {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.user-detail-container {
	display: flex;
	font-size: 1.2em;
	line-height: 1.2em;
	flex-direction: column;
	width: 100%;
	color: #E6D5B8;
	align-items: start;
	background-color: rgba(230, 213, 184, 0.2);
	padding: 0.2em;
	box-sizing: border-box;
	border-radius: 0.2em;
}

.topic-conversation {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
}

.cs-topic-detail-client-chat {
	background-color: rgba(255, 137, 0, 0.2);
	color: #FF8900;
	padding: 0.4em;
	border-radius: 0.4em;
	margin: 0.4em 0;
	width: fit-content;
	box-sizing: border-box;
}

.cs-topic-detail-cs-chat {
	background-color: rgba(230, 213, 184, 0.2);
	color: #E6D5B8;
	padding: 0.4em;
	border-radius: 0.4em;
	margin: 0.4em 0;
	width: fit-content;
	box-sizing: border-box;
}

.editor-container {
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding: 0.2em 0.2em 2em 0.2em;
	align-items: flex-start;
	background-color: #1f1f35;
}

textarea {

	background-color: transparent;
	border: 1px solid #FF8900;
	border-radius: 0.2em;
	flex: 1;
	color: #FF8900;
	font-size: 1.2em;
	min-height: calc(3 * 1.4em); /* 最少3行 */
	max-height: calc(10 * 1.4em); /* 最多10行 */
	line-height: 1.4em;
	overflow-y: auto;
	resize: vertical;
	padding: 5px;
	box-sizing: border-box;
}

textarea:focus {
	border: 1px solid #FF8900;
}

textarea:focus-visible {
	outline: #ffad50 auto 1px;
}

/* 其他浏览器 */
textarea::placeholder {
	color: #c76c00;
}

.editor-btn-container {
	display: flex;
	flex-direction: column;
}

.topic-detail-container {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
}

.chat-box-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.chat-box-client-msg-container {
	color: #FF8900;
	word-wrap: break-word;
}

.chat-box-client-img-container {
	max-width: 50%;
}

.chat-box-my-img-container {
	max-width: 50%;
}

.chat-box-my-msg-container {
	color: #E6D5B8;
	word-wrap: break-word;
}

.topic-type-container {
	display: flex;
	flex-direction: column;
	border: 1px solid #E6D5B8;
	padding: 0.2em;
	border-radius: 0.2em;
}

.topic-type-label {
	word-break: keep-all;
	white-space: nowrap;
	color: rgba(230, 213, 184, 0.8);
	line-height: 1.2em;
}

.ticket-img {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.device-info-label {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.ticket-img-container {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.ticket-img-wrapper {
	width: 5em;
	height: 5em;
	background-color: #000;
	padding: 0.2em;
	box-sizing: border-box;
}

.device-info-container {
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(230, 213, 184, 0.5);
	padding: 0.2em;
	border-radius: 0.2em;
	margin: 0.4em 0;
	box-sizing: border-box;
}

.device-info-row {
	display: flex;
	flex-direction: row;
	color: #E6D5B8;
}

.flex-table {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	font-family: Arial, sans-serif;
}

.table-header, .table-row {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #E6D5B8;
	width: 100%;
	min-width: 100%;
	box-sizing: border-box;
}

.table-header {
	background-color: rgba(230, 213, 184, 0.3);
	font-weight: bold;
}

.table-cell {
	padding: 0.2em;
	text-align: center;
	/* 关键：添加这些属性确保内容不会撑开单元格 */
	box-sizing: border-box;
	min-width: 0; /* 防止flex项目超出容器 */
	word-wrap: break-word; /* 长文本自动换行 */
	color: #E6D5B8;
}

.table-cell:nth-child(1),
.table-cell:nth-child(2) {
	flex: 0 0 auto; /* 不伸缩，保持内容宽度 */
	white-space: nowrap; /* 防止文本换行 */
}

.table-cell:nth-child(3),
.table-cell:nth-child(4) {
	flex: 1 1 0; /* 可伸缩，平分剩余空间 */
}


</style>