<template>

	<div class="customer-service-page-container">
		<TopicDetailModal v-if="showTopicDetail === true" @close="onTopicDetailClose" @ticketUpdated="refreshAllTopics"
		                  :ticketItem="this.selectedItem"/>
		<AirportTicketDetailModal v-if="showAirportTicketDetail === true" @close="onAirportTicketDetailClose"
		                          @ticketUpdated="refreshAllTopics"
		                          :ticketItem="this.selectedItem"/>
		<WebsiteChatDetailModal v-if="showWebsiteChatModal" :chatItem="this.selectedItem" @close="onWebsiteChatModalClose" @msgSent="onWebsiteMsgSent"/>

		<ConfirmModal v-if="showConfirmModal===true" @cancel="onConfirmModalCancel" @confirm="onConfirmModalConfirm"
		              :confirm-msg="this.confirmMsg"/>
		<div class="cs-view-switch-container">
			<div class="cs-view-switch-wrapper">
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'vpn'}"
				     @click="this.onViewSwitcherClicked('vpn')">类目一
				</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'airport'}"
				     @click="this.onViewSwitcherClicked('airport')">类目二
				</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'website'}"
				     @click="this.onViewSwitcherClicked('website')">网页
				</div>
			</div>
		</div>
		<div class="cs-main-container" v-if="this.currentViewSwitcher === 'vpn'">
			<div class="btn-container">
				<a-pagination v-model:current="this.page" simple v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onVpnPageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>

				<div class="header-btn" @click="onRefreshBtnClicked">刷新</div>
			</div>

			<div class="list-container">
				<template v-for="item in this.vpnTopicList" :key="item.feedbackTopicId">
					<div class="item-container" @click="onItemClick(item)">
						<div class="item-title-container">
							<div class="item-title">
								{{ this.formatItemTitle(item.userDescription) }}
							</div>
							<img v-if="item?.isClosed !== true" src="../images/close_topic.svg" alt="close topic"
							     @click="onCloseTicket(item, $event)">
						</div>

						<div class="item-email">
							{{ item.email }}
						</div>
						<div class="item-email">
							状态：<span :style="this.getTicketStatusStyle(item)">{{ item.topicState }}</span>
						</div>
						<div class="item-time">
							创建时间：{{ item.createdTimeString }}
						</div>
						<div class="item-time">
							更新时间：{{ item.updatedTimeString }}
						</div>
					</div>
				</template>
			</div>
		</div>

		<div class="cs-main-container" v-if="this.currentViewSwitcher === 'airport'">

			<div class="btn-container">
				<a-pagination v-model:current="this.page" simple v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onAirportPageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>

				<div class="header-btn" @click="onRefreshBtnClicked">刷新</div>
			</div>


			<div class="list-container">
				<template v-for="item in this.airportTicketList" :key="item.ticketId">
					<div class="item-container" @click="onItemClick(item)">
						<div class="item-title-container">
							<div class="item-title">
								{{ this.formatItemTitle(item.subject) }}
							</div>
							<img v-if="item?.isClosed !== true" src="../images/close_topic.svg" alt="close topic"
							     @click="onCloseTicket(item, $event)">
						</div>

						<div class="item-email">
							{{ item.userEmail }}
						</div>
						<div class="item-email">
							状态：<span :style="this.getAirportTicketStatusStyle(item)">{{
								this.getAirportTicketStatusText(item)
							}}</span>
						</div>
						<div class="item-time">
							创建时间：{{ item.createdTimeString }}
						</div>
						<div class="item-time">
							更新时间：{{ item.updateTimeString }}
						</div>
					</div>
				</template>
			</div>

		</div>
		<div class="cs-main-container" v-if="this.currentViewSwitcher === 'website'">

			<div class="btn-container">
				<a-pagination v-model:current="this.page" simple v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onWebsitePageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>

				<div class="header-btn" @click="onRefreshBtnClicked">刷新</div>
			</div>


			<div class="list-container">
				<template v-for="item in this.websiteChatList" :key="item.recordId">
					<div class="item-container" @click="onItemClick(item)">
						<div class="item-title-container">
							<div class="item-title">
								{{ item?.platformInfo?.description }}
							</div>
						</div>

						<div class="item-email">
							fp：{{item.fp}}
						</div>
						<div class="item-email">
							状态：<span :style="this.getWebsiteChatStatusStyle(item)">{{
								this.getWebsiteChatStatusText(item)
							}}</span>
						</div>
						<div class="item-time">
							创建时间：{{ item.createTimeString }}
						</div>
					</div>
				</template>
			</div>

		</div>
	</div>

</template>

<script>
import {
	getRequestAsync,
	hideLoading,
	postRequestAsync,
	showLoading,
	convertTimestampToTimeString,
	verifyFp
} from "@/utils/methods";
import TopicDetailModal from "../components/TopicDetailModal.vue";
import AirportTicketDetailModal from "../components/AirportTicketDetailModal.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import WebsiteChatDetailModal from "../components/WebsiteChatDetailModal.vue";

export default {
	name: "CustomerServicePage",
	components: {
		TopicDetailModal,
		ConfirmModal,
		AirportTicketDetailModal,
		WebsiteChatDetailModal
	},
	data() {
		return {
			vpnTopicList: [],
			airportTicketList: [],
			websiteChatList: [],
			page: 1,
			pageSize: 20,
			totalCount: 0,
			currentViewSwitcher: 'vpn',
			showTopicDetail: false,
			showAirportTicketDetail: false,
			showWebsiteChatModal: false,
			selectedItem: null,
			showConfirmModal: false,
			confirmMsg: '',
			confirmModalType: null
		}
	},
	async beforeMount() {
		this.isLogin = await this.checkLogin()
		if (this.isLogin) {
			await this.getAllVpnTopics(false)
		}
	},
	methods: {
		async onRefreshBtnClicked() {
			if (this.currentViewSwitcher === 'vpn') {
				await this.getAllVpnTopics(true)
			} else if (this.currentViewSwitcher === 'website') {
				await this.getAllWebsiteChats()
			} else {
				await this.getAllAirportTickets()
			}


		},
		async checkLogin() {
			showLoading()
			const isVerified = await verifyFp()
			hideLoading()
			return isVerified
		},
		async getAllVpnTopics(isManual = false) {
			showLoading()
			const result = await postRequestAsync('admin/cs/getVpnAllTopics', {page: this.page})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.vpnTopicList = result?.msg?.list
				this.totalCount = result?.msg?.totalCount
				if (isManual === true) {
					this.$message.success('刷新成功')
				}
			}
		},
		async getAllAirportTickets() {
			showLoading()
			const result = await postRequestAsync('admin/cs/getAirportTickets', {page: this.page})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.airportTicketList = result?.msg?.list
				this.totalCount = result?.msg?.totalCount
			}
		},
		async getAllWebsiteChats() {
			showLoading()
			const result = await postRequestAsync('admin/cs/getAllWebsiteChats', {page: this.page})
			hideLoading()
			if (result?.code === 200 && result?.msg?.list) {
				this.websiteChatList = result?.msg?.list
				this.totalCount = result?.msg?.totalCount
			}
		},
		async refreshAllTopics() {
			if (this.currentViewSwitcher === 'vpn') {
				await this.getAllVpnTopics()
			} else {
				await this.getAllAirportTickets()
			}

		},
		async onTopicItemClicked(item) {
			showLoading()
			const result = await postRequestAsync('admin/cs/getTopicDetail', {
				topicId: item.feedbackTopicId,
				ticketId: item.ticketId
			})
			hideLoading()
			if (result.code === 200) {
				this.currentDisplayChat = result.msg
				this.currentDisplayTopic = item
				this.showMenuMobile = false
				if (result?.extra) {
					this.userReferByCode = result?.extra?.referCode || null
					this.userReferByEmail = result?.extra?.referByEmail || null
					this.userRegisterTime = result?.extra?.registerTime ? convertTimestampToTimeString(result?.extra?.registerTime) : null
					this.userPlanExpireTime = result?.extra?.planExpireTime ? convertTimestampToTimeString(result?.extra?.planExpireTime) : null
					this.usrePaymentRecords = result?.extra?.payments
				}
			} else {
				this.$message.error('获取反馈信息失败');
			}
		},
		getUserPaymentsDisplay() {
			if (Array.isArray(this.usrePaymentRecords) && this.usrePaymentRecords?.length > 0) {
				return `${this.usrePaymentRecords?.length}笔`
			}
			return '无'
		},
		async sendMsgReply() {
			if (this.currentDisplayTopic && this.currentDisplayTopic.ticketId) {
				await this.replyAirportTicket(this.currentDisplayTopic.ticketId)
				return
			}
			if (!this.currentDisplayTopic || !this.currentDisplayTopic.feedbackTopicId) {
				this.$message.error('请选择一个反馈');
				return
			}
			if (!this.toSendMsg) {
				this.$message.error('请输入信息');
				return
			}
			showLoading()
			const result = await postRequestAsync('customerServicePanel/sendTopicReply', {
				topicId: this.currentDisplayTopic.feedbackTopicId,
				msg: this.toSendMsg
			})
			hideLoading()
			if (result.code === 200) {
				this.$message.success('发送成功');
				this.toSendMsg = ''
				this.currentDisplayChat = this.msg
			} else {
				this.$message.error('发送失败');
			}
		},
		async replyAirportTicket(ticketId) {
			showLoading()
			const result = await postRequestAsync('airport/ticket/replyAirportTicket', {
				ticketId,
				replyMsg: this.toSendMsg
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('发送成功');
				this.currentDisplayTopic = result.msg
			}
		},
		onShowMenuClicked() {
			console.log('onShowMenuClicked')
			this.showMenuMobile = true
		},
		onCloseMenuClicked() {
			this.showMenuMobile = false
		},
		async onVpnPageChanged(data) {

			this.page = data
			await this.getAllVpnTopics()

		},
		async onAirportPageChanged(data) {
			this.page = data
			await this.getAllAirportTickets()
		},
		async onWebsitePageChanged(data) {
			this.page = data
			await this.getAllWebsiteChats()
		},
		formatItemTitle(text) {
			if (!text) {
				return ''
			}
			if (text.length <= 10) {
				return text
			}
			return `${text.slice(0, 10)}...`;
		},

		getTicketStatusStyle(item) {
			if (item.topicState === '已回复') {
				return {
					'color': 'green'
				}
			}
			if (item.topicState === '等待回复') {
				return {
					'color': 'red'
				}
			}

		},
		getAirportTicketStatusStyle(item) {
			if (item.ticketStatus === 'replied') {
				return {
					'color': 'green'
				}
			}
			if (item.ticketStatus === 'wait_for_reply') {
				return {
					'color': 'red'
				}
			}

		},
		getWebsiteChatStatusStyle(item) {
			if (item.isReply === true) {
				return {
					'color': 'green'
				}
			}

			return {
				'color': 'red'
			}

		},
		getAirportTicketStatusText(item) {
			if (item.ticketStatus === 'replied') {
				return '已回复'
			}
			if (item.ticketStatus === 'wait_for_reply') {
				return '等待回复'
			}

		},
		getWebsiteChatStatusText(item) {
			if (item.isReply === true) {
				return '已回复'
			}

			return '等待回复'

		},
		onViewSwitcherClicked(viewType) {

			this.currentViewSwitcher = viewType
			this.page = 1;
			if (viewType === 'airport') {
				this.getAllAirportTickets()
			} else if (viewType === 'website') {
				this.getAllWebsiteChats()
			} else {
				this.getAllVpnTopics()
			}
		},
		onItemClick(item) {
			if (this.currentViewSwitcher === 'vpn') {

				this.showTopicDetail = true
			} else if (this.currentViewSwitcher === 'website') {
				this.showWebsiteChatModal = true
			} else {
				this.showAirportTicketDetail = true

			}
			this.selectedItem = item
		},
		onTopicDetailClose() {
			this.showTopicDetail = false
		},
		onAirportTicketDetailClose() {
			this.showAirportTicketDetail = false
		},
		onWebsiteChatModalClose() {
			this.showWebsiteChatModal = false
		},
		async onWebsiteMsgSent() {
			await this.getAllWebsiteChats()
		},
		onConfirmModalCancel() {
			this.showConfirmModal = false
		},
		async onConfirmModalConfirm() {
			this.showConfirmModal = false
			if (this.confirmModalType === 'CLOSE_TICKET') {
				await this.doCloseTicket()
			}
		},
		async doCloseTicket() {
			showLoading()
			const result = await postRequestAsync('admin/cs/closeVpnTicket', {
				ticketId: this.selectedItem?.feedbackTopicId
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('发送成功');
				await this.getAllVpnTopics()
			}

		},
		onCloseTicket(item, event) {
			event.stopPropagation()
			this.confirmMsg = '确认关闭工单？'
			this.showConfirmModal = true
			this.confirmModalType = 'CLOSE_TICKET'
			this.selectedItem = item
		}
	}
}
</script>

<style scoped>
.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
	padding: 1em 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 900px) {

}

.item-container:hover {
	transform: scale(1.01);
}

.tab-btn-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.item-title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
</style>